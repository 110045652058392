//闭环信息
<template>
  <div class="closedLoop" :id="scrollBoxId">
     <!-- <icon-button
            content="保存"
            icon="iconfont iconshanchu1"
            @click="submit('save')"
          />
     <icon-button
            content="提交"
            icon="iconfont iconshanchu1"
            @click="submit('submit')"
          /> -->
    <div >
      <text-title index='01' label="监管账户"  data-title="监管账户" class="modelBox"></text-title>
      <base-form
        :componentList="superviseForm"
        :formAttrs="{
          model: formData.finSuperviseBankAccount,
          labelWidth: '100px',
        }"

        :showBtns="false"
        class="formStyle"
        ref="superviseForm"
      >
      </base-form>
      <!-- 监管账户锁定 -->
      <div class="hand">
        <!-- <p class="handText">*监管账户系统锁定</p> -->
        <title-center :rules="true" name="监管账户系统锁定" />
        <div class="addButton" @click="addLockItem">
          <i class="el-icon-plus"></i>
        </div>
      </div>
      <base-table
        :columns="accountLockColumn"
        :tableAttrs="{
          data: formData.ddCustodialAccountInfoVOs,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
        <template slot="code" slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
        <!-- 监管账户锁定 -->
        <template slot="lockableState" slot-scope="scope">
          <el-radio-group v-model="scope.row.lockableState">
            <el-radio :label="'1'">可实现</el-radio>
            <el-radio :label="'0'">其他</el-radio>
          </el-radio-group>
          <el-input
            v-show="scope.row.lockableState === '0'"
            class="othersInput"
            placeholder="请输入"
             maxlength="200"
            v-model="scope.row.unlockableDescription"
          ></el-input>
        </template>
        <template slot="action" slot-scope="scope">
          <icon-button
            content="删除"
            icon="iconfont iconshanchu1"
            @click="delLockItem(scope.$index)"
          />
        </template>
        <!-- 项目公司名称 -->
        <template slot="xmgsName" slot-scope="scope">
          <!-- <div v-if="scope.row.add">
            <el-input
              v-model="scope.row.xmgsName"
              placeholder="请输入项目公司名称"
              maxlength="50"
            ></el-input>
          </div> -->
          <el-autocomplete
            class="inline-input"
            v-model="scope.row.xmgsName"
            :fetch-suggestions="querySearch"
            placeholder="请输入项目公司名称"

             :maxlength="50"
          ></el-autocomplete>

        </template>
      </base-table>
      <!-- 通知书寄送 -->
      <div class="hand">

         <title-center  name="通知书寄送" />
        <div class="addButton" @click="addNoticeItem">
          <i class="el-icon-plus"></i>
        </div>
      </div>
      <base-table
        :columns="noticeColumn"
        :tableAttrs="{
          data: formData.ddSendingNoticesAddressVOs,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
        <template slot="code" slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
        <!-- 项目公司名称 -->
        <template slot="xmgsName" slot-scope="scope">
          <el-autocomplete
            class="inline-input"
            v-model="scope.row.xmgsName"
            :fetch-suggestions="querySearch"
            placeholder="请输入项目公司名称"

             :maxlength="50"
          ></el-autocomplete>

        </template>
        <template slot="contactName" slot-scope="scope">
          <sz-input
            placeholder="请输入联络人姓名"
            v-model="scope.row.contactName"

          ></sz-input>

        </template>
        <template slot="phone" slot-scope="scope">
          <sz-input
            placeholder="请输入电话"
            :maxlength="11"
            @input="scope.row.phone = scope.row.phone.replace(/[^\d]/g, '')"
            v-model="scope.row.phone"
            @blur="blurPhone(scope.row.phone, scope.$index)"

          ></sz-input>

        </template>
        <template slot="address" slot-scope="scope">
          <sz-input
            placeholder="请输入收件地址"
            v-model="scope.row.address"

             :maxlength="200"
          ></sz-input>

        </template>
        <template slot="remark" slot-scope="scope">
          <sz-input
            placeholder="请输入备注"
            v-model="scope.row.remark"
            :maxlength='100'

            :show-word-limit='true'
          ></sz-input>

        </template>
        <template slot="action" slot-scope="scope">
          <icon-button
            content="删除"
            icon="iconfont iconshanchu1"
            @click="delDdSendingNoticesAddressVOs(scope.$index)"
          />
        </template>
      </base-table>
    </div>
    <div >
      <text-title index='02' :required="true"  label="贷后管理措施" data-title="贷后管理措施" class="modelBox"></text-title>
      <div class="measures">

        <el-input
          type="textarea"
          placeholder="请输入"
          v-model="postloanManagementMeasuresVO.postloanManagementMeasures"
          maxlength="500"
          show-word-limit
          rows="5"
        ></el-input>
      </div>
      <enclosure-upload :fileInfos="postloanManagementMeasuresVO.fileInfos"></enclosure-upload>
    </div>
     <div >
      <text-title index='03' label="文件生成与下载" data-title="文件生成与下载" class="modelBox"></text-title>
       <base-form
        :componentList="fileConfig"
        :formAttrs="{
          model: fileData,
          labelWidth: '100px',
        }"
        :showBtns="false"
        class="formStyle"
        ref="fileForm"
      />
    </div>
    <scroll-fool :scrollData="scrollData" :scrollBoxId='scrollBoxId'/>
    <pre-view  :fileId="fileData.fileId" :fileType="fileData.fileType" :isOpen="true" :count="fileCount" />
  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import TitleCenter from '@/pages/business/components/title-center.vue'
import ScrollFool from '@/components/packages/scroll-fool/scroll-fool.vue'
import { contractDdApi, financingApplyApi, progressApi } from '@/api/businessApi'
import SzInput from '@/components/input/sz-input/sz-input.vue'
import { phoneReg } from '@/config/reg-config'
import Storage from '@/utils/storage'
import BaseForm from '@/components/common/base-form/base-form.vue'
import TextTitle from '@/components/packages/text-title/text-title.vue'
import baseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import EnclosureUpload from '../components/enclosure-upload.vue'
import {
  regulatoryAccount,
  accountLockTable,
  noticeTable,
  supervise,
  fileConfig
} from '../utils/closed-loop-info-config'
export default {
  components: {
    TextTitle,
    baseTable,
    IconButton,
    EnclosureUpload,
    BaseForm,
    SzInput,
    ScrollFool,
    TitleCenter,
    PreView
  },
  props: {
    tabsTitles: Array, // 标题错误状态
    showMain: Number, // 当前显示页面
    postloanManagementMeasuresVO: Object,
    closedData: Object,
    businessId: String,
    fileInfos: Object // 文件信息
  },
  data () {
    return {
      fileCount: 0,
      showPage: 3,
      gysId: '',
      regulatoryAccountData: { fileName: '8848' }, // 监管账户信息
      count: 0,
      accountLockData: [{}], // 监管账户锁定数据
      othersInfo: '', // 其他补充内容
      noticekData: [{}], // 通知书数据
      measuresTextarea: '', // 措施数据
      scrollBoxId: 'cloBox',
      scrollData: [
        { label: '监管账户', id: 14 },
        { label: '贷后管理措施', id: 15 }
      ]
    }
  },

  computed: {
    fileData: {
      get () {
        console.log(this.fileInfos, 'this.fileInfos')
        return this.fileInfos
      },
      set (val) {

      }
    },
    api () {
      return contractDdApi
    },
    fileConfig () {
      return fileConfig(this)
    },
    accounts () {
      return this.formatAccounts(this.formData.accounts)
    },
    formData: {
      get () {
        return this.closedData
      },
      set (val) {
        this.$emit('changeClosedData', val)
      }
    },
    superviseForm () {
      return supervise(this)
    },
    regulatoryAccountList () {
      return regulatoryAccount(this)
    },
    accountLockColumn () {
      return accountLockTable(this)
    },
    noticeColumn () {
      return noticeTable(this)
    }
  },
  watch: {
    showMain (val) {
      if (val === 3 && !this.tabsTitles[3].pass) {
        this.saveAll('submit')
      } else if (val === 3 && this.tabsTitles[3].pass) {
        this.$refs.superviseForm.clearValidate()
        this.$refs.fileForm.clearValidate()
      }
    }
  },
  methods: {
    // 删除文件
    delFile () {
      this.$set(this.fileInfos, 'fileId', '')
      this.$set(this.fileInfos, 'fileName', '')
    },
    // 文件生成
    saveData () {
      progressApi.getCheckForbiddenOrNot({ businessId: this.$route.query.businessId }).then((res) => {
        if (!res.data.status) {
          this.warning(res.data.errorMsg)
          this.$router.back()
        } else {
          this.$emit('generate', '')
        }
      })
    },
    // 文件生成接口
    generate () {
      financingApplyApi.getGenerateDo({ businessId: this.$route.query.businessId, type: 'WXF_DUE' }).then(res => {
        this.success('文件生成成功')
        this.$set(this.fileInfos, 'fileId', res.data.keyId)
        this.$set(this.fileInfos, 'fileName', res.data.fileName)
        console.log(this.fileInfos, '123123123')
      })
    },
    // 开发预览暂存
    previewShow () {
      const fileArr = this.fileData.fileName.split('.')
      this.fileData.fileType = fileArr[fileArr.length - 1]
      this.fileCount++
    },

    // 监管账户提交保存
    saveAll (type) {
      const data = this.formData
      data.businessId = this.businessId
      data.ddCustodialAccountInfoDTOs = data.ddCustodialAccountInfoVOs
      data.ddSendingNoticesAddressDTOs = data.ddSendingNoticesAddressVOs
      for (let i = 0; i <= data.ddCustodialAccountInfoDTOs.length; i++) {
        if (data.ddCustodialAccountInfoDTOs[i] && !data.ddCustodialAccountInfoDTOs[i].xmgsName) {
          data.ddCustodialAccountInfoDTOs.splice(i, 1)
          i--
        }
        for (let i = 0; i <= data.ddSendingNoticesAddressDTOs.length; i++) {
          if (data.ddSendingNoticesAddressDTOs[i] && !data.ddSendingNoticesAddressDTOs[i].xmgsName) {
            data.ddSendingNoticesAddressDTOs.splice(i, 1)
            i--
          }
        }
        if (type === 'save') {
          this.api.saveDdAccountInfo(data).then(res => {

          })
        } else if (type === 'submit') {
          this.$refs.superviseForm.validate((valid) => {
            if (!valid) {
              if (this.showMain === this.showPage) {
                this.$message.closeAll()
                this.warning('请选择监管账户信息')
              }
              this.$emit('errMsg', this.showPage, false)
            }
          })
          this.$refs.fileForm.validate((valid) => {
            if (!valid) {
              this.$emit('errMsg', this.showPage, false)
            }
          })
          if (!this.postloanManagementMeasuresVO.postloanManagementMeasures) {
            if (this.showMain === this.showPage) {
              this.$message.closeAll()
              this.warning('请填写贷后管理措施')
            }
            this.$emit('errMsg', this.showPage, false)
            return false
          }
          if (data.ddCustodialAccountInfoDTOs.length === 0) {
            if (this.showMain === this.showPage) {
              this.$message.closeAll()
              this.warning('请添加监管账户系统锁定信息')
            }
            this.$emit('errMsg', this.showPage, false)
            return false
          }
          if (!this.postloanManagementMeasuresVO.postloanManagementMeasures) {
            if (this.showMain === this.showPage) {
              this.$message.closeAll()
              this.warning('请填写贷后管理措施')
            }
            this.$emit('errMsg', this.showPage, false)
            return false
          }

          this.$refs.fileForm.validate((valid) => {
            if (valid) {
              this.$emit('errMsg', this.showPage, true)
              this.api.submitDdAccountInfo(data).then(res => {
              })
            } else {
              console.error(this.showMain === this.showPage, this.showMain, this.showPage)
              if (this.showMain === this.showPage) {
                this.$message.closeAll()
                this.warning('请生成或上传尽调报告文件')
              }
              this.$emit('errMsg', this.showPage, false)
            }
          })
        } else if (type === 'generate') {
          this.$refs.superviseForm.validate((valid) => {
            if (!valid) {
              if (this.showMain === this.showPage) {
                this.$message.closeAll()
                this.warning('请选择监管账户信息')
              }
              this.$emit('errMsg', this.showPage, false)
            }
          })
          if (!this.postloanManagementMeasuresVO.postloanManagementMeasures) {
            if (this.showMain === this.showPage) {
              this.$message.closeAll()
              this.warning('请填写贷后管理措施')
            }
            this.$emit('errMsg', this.showPage, false)
            return false
          }
          if (data.ddCustodialAccountInfoDTOs.length === 0) {
            if (this.showMain === this.showPage) {
              this.$message.closeAll()
              this.warning('请添加监管账户系统锁定信息')
            }
            this.$emit('errMsg', this.showPage, false)
            return false
          }
          // 保存数据
          this.api.saveDdAccountInfo(data).then(res => {

          })
        }
      }
    },
    // 手机号校验
    blurPhone (data, index) {
      if (!phoneReg.test(data)) {
        this.$set(this.formData.ddSendingNoticesAddressVOs[index], 'phone', '')
        if (this.showMain === this.showPage) {
          this.warning('请输入正确的手机号')
        }
        this.$emit('errMsg', this.showPage, false)
        return false
      }
    },
    formatAccounts (data) {
      data.forEach((item) => {
        item.value = item.xmgsName
      })
      return data
    },
    querySearch (queryString, cb) {
      console.log(queryString, this.formData.accounts)
      const restaurants = this.accounts
      const results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        const key = JSON.parse(JSON.stringify(restaurant))
        console.log(key, 'restaurant')
        return key.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    // 查询尽调监管账户信息 锁定 及通知书寄送信息
    getDdAccountInfo (superviseBankAccountId) {
      this.api.getDdAccountInfo({ businessId: this.businessId, superviseBankAccountId: superviseBankAccountId }).then(res => {
        console.log(res.data, '监管账户信息 锁定 及通知书寄送信息')
        this.formData.ddCustodialAccountInfoVOs = res.data.ddCustodialAccountInfoVOs
        this.formData.ddSendingNoticesAddressVOs = res.data.ddSendingNoticesAddressVOs
      })
    },
    // 选择监管账户
    addSelectAccount (data) {
      this.$set(this.formData.finSuperviseBankAccount, 'account', data.account)
      this.$set(this.formData.finSuperviseBankAccount, 'bankName', data.bankName)
      this.$set(this.formData.finSuperviseBankAccount, 'accountNo', data.accountNo)
      // this.$set(this.formData.finSuperviseBankAccount, 'keyId', data.keyId)
    },
    // 增加锁定数据
    addLockItem () {
      this.formData.ddCustodialAccountInfoVOs.push({ add: true, lockableState: '1' })
    },
    // 删除锁定数据
    delLockItem (index) {
      this.formData.ddCustodialAccountInfoVOs.splice(index, 1)
    },
    // 增加通知书
    addNoticeItem () {
      this.formData.ddSendingNoticesAddressVOs.push({ add: true })
    },
    // 删除通知书
    delDdSendingNoticesAddressVOs (index) {
      this.formData.ddSendingNoticesAddressVOs.splice(index, 1)
    }
  },
  mounted () {
    this.gysId = Storage.getLocal('contractInfo').gysId
  }
}
</script>
<style lang="scss" scoped>
.closedLoop {
  // padding: 10px;
  overflow-y: auto;
}
.addButton {
  background: #4A7CF0;
  width: 20px;
  height: 20px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    background-color: #6996FF;
  }
}

.hand {
  height: 30px;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 0;
  .handText {
    color: red;
  }
}
.othersInput {
  max-width: 120px;
  margin-left: 10px;
}
.measures {
  padding: 10px 0;
  .el-textarea{
    padding: 10px;
    width: calc(100% - 20px);
  }
}
</style>
