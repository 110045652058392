//附件上传
<template>
<div class="enclosureUpload">
  <div class="text">
      <span>附件:</span>
    <el-upload
      class="upload-demo"
      action=""
      accept=".doc,.docx,.pdf,.xls,.xlsx,.jpg,.jpeg,.png,.bmp"
      :http-request="url => uploadFile(url)"
      :show-file-list="false"
    >
      <icon-button
      content="上传"
      icon="iconfont iconshangchuan"
      />
    </el-upload>
    </div>
  <div class="rightContent">
    <div class="fileBox" v-for="(item,index) in fileList" :key="index">
      <el-tooltip
          effect="dark"
          :content="item.fileName"
          placement="top"
          :disabled="disabled"
          :enterable="false"
          v-if="item.showAll"
        >
        <div class="fileName fileStyle" ref="fileName">
          <span>{{item.name+"..."}}</span>
          <span>{{item.suffix}}</span>
        </div>
        </el-tooltip>
        <div v-else class="fileStyle">{{ item.fileName }}</div>
         <icon-button
          content="预览"
          icon="iconfont iconyulan"
          @click="previewShow(item,index)"
          />
           <icon-button
           @click="dowload(item,index)"
           content="下载"
           icon="iconfont iconxiazai"
         />
      <icon-button
      @click="delfile(item,index)"
      content="删除"
      icon="iconfont iconshanchu1"
         />
    </div>
  </div>
  <!-- 预览 -->
  <pre-view :fileId='fileId' :count="count" :fileType="fileType" :isOpen="true" />
</div>

</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'

export default {
  props: {
    fileInfos: Array
  },
  components: { IconButton, PreView },
  data () {
    return {
      count: 0,
      fileId: '',
      fileType: '',
      fileName: '',
      showBtn: true,
      name: '',
      suffix: '',
      disabled: true,
      showAll: false
    }
  },
  computed: {
    fileList: {
      get () {
        return this.fileInfos
      },
      set (val) {

      }
    }
  },
  methods: {
    // 下载
    dowload (data, index) {
      if (!data.fileId) {
        this.warning('抱歉暂无资源')
        return
      }
      const params = {
        keyId: data.fileId
      }
      downAPi
        .download(params)
        .then((res) => {
          downFile(res)
        })
        .catch((e) => {
          this.error(e)
        })
    },
    uploadFile (param) {
      if (this.fileList && this.fileList.length > 9) {
        return this.warning('最多上传10份文件')
      }
      this.fileName = JSON.parse(JSON.stringify(param.file.name))
      if (this.fileName.length > 80) {
        return this.warning('文件名称小于80字符')
      }
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }
      this.suffix = param.file.name.substr(param.file.name.lastIndexOf('.') + 1, 4)
      this.name = param.file.name.substr(0, param.file.name.lastIndexOf('.'))
      const uploadArr = ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'png', 'jpg', 'jpeg', 'gif', 'bmp']
      const isOk = uploadArr.some(item => item === this.suffix.toLowerCase())
      if (!isOk) {
        this.warning(`文件类型错误${this.suffix}`)
        return
      }

      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      fileAPi.file(formData).then((res) => {
        if (res.code === '0') {
          this.showAll = false
          if (this.name.length > 11) {
            this.name = this.name.substr(0, 11)
            this.showAll = true
          }
          this.showBtn = false
          this.success('文件上传成功')
          this.fileList.push({ name: this.name, suffix: this.suffix, showAll: this.showAll, ...res.data, fileId: res.data.keyId })
          this.$emit('input', res.data.keyId)
          this.$emit('inputSuccess', res.data.fileName)
          this.$nextTick(() => {
            if (this.showAll) {
              this.getwidth()
            }
          })
        } else {
          this.error('文件上传失败')
        }
      })
    },
    getwidth () {
      const dom = this.$refs.fileName
      this.disabled = dom.offsetWidth >= dom.scrollWidth
    },
    previewShow (item) {
      console.log(item)
      this.fileId = item.fileId
      const fileArr = item.fileName.split('.')
      this.fileType = fileArr[fileArr.length - 1]
      this.count++
    },
    delfile (data, index) {
      this.$stateConfirm({
        title: '提示',
        message: '确认删除吗？',
        show: true,
        type: 'warning'
      }).then(() => {
        this.fileList.splice(index, 1)
      })
      this.fileName = ''
      this.$emit('input', '')
      this.$emit('inputSuccess', '')
      this.showBtn = true
    }
  }
}
</script>
<style lang='scss' scoped>
.enclosureUpload{
  margin-top: 8px;
  padding: 0 10px;
}
/deep/ .el-textarea {
    height: auto !important;
  }
  .text{
    width: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
  }
  .rightContent{
    width: 100%;
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
    .fileBox{
      padding-right: 50px;
      display: flex;
      align-content: center;
      justify-content: flex-start;
    }
}
.fileStyle{
    font-size: 14px;
    color: #2862E7;
    line-height: 30px;
    padding-right: 20px;
}
.fileName{
  display: flex;
  justify-content: flex-start;
  align-content: center;
}
</style>
